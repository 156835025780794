import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Form, FormSpy } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { logErr } from '../../utils/logger'
import validation from '../../validation/payment'
import CustomerFields from './CustomerFields'
import AddressFields from './AddressFields'
import ErrorMessage from '../common/Error'
import Button from '../common/Button'
import api from '../../api'
import { useUserContext } from '../../contexts/User/userContext'
import {
  PushToDatalayer,
  trackUserAction
} from '../../utils/analytics-tracking'
import { usePaymentContext } from '../../contexts/Payment/paymentContext'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import { useCountryContext } from '../common/CountryProvider'
import { getApiPhoneNumber } from './checkoutHelper'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'
import { INLINES } from '@contentful/rich-text-types'
import RichText from '../../utils/richTextUtils'
import { getUserStateLink } from '../../utils/helpers'
import useSamlCookie from '../../utils/useSamlCookie'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(tz)

const focusOnErrors = createDecorator()

export function PaymentForm({ setLoading }) {
  const formRef = useRef()

  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const { user, refreshUserState, setUserProps, enroll } = useUserContext()

  const samlCookie = useSamlCookie()

  const { paymentValues, setPaymentValues } = usePaymentContext()
  const { countries } = useEmployerContext()
  const { currentCountry, getCountryByCode } = useCountryContext()

  const [formErrors, setFormErrors] = useState([])

  const validationFn = useMemo(() => validation(translations), [translations])

  const samlDobFormatted = useMemo(() => {
    return samlCookie?.attributes?.DOB?.[0]
      ? dayjs(
          samlCookie?.attributes?.DOB?.[0],
          Intl.DateTimeFormat().resolvedOptions().timeZone
        ).toDate()
      : undefined
  }, [samlCookie])

  const samlPhoneFormatted = useMemo(() => {
    const phoneNumber = samlCookie?.attributes?.mobilePhone?.[0]
    if (phoneNumber) {
      // Make sure countries with a +1 north american phone code have the +1 prepended.
      const currentCountryCode = paymentValues.shipping_address?.country
      const currentCountry =
        currentCountryCode && getCountryByCode(currentCountryCode)

      const apiPhoneNumber = getApiPhoneNumber(currentCountry, phoneNumber)

      const notValid = validationFn({
        customer: { phoneNumber: apiPhoneNumber }
      })

      if (notValid.customer.phoneNumber) {
        return undefined
      } else {
        return apiPhoneNumber
      }
    } else {
      return undefined
    }
  }, [
    getCountryByCode,
    paymentValues.shipping_address?.country,
    samlCookie?.attributes?.mobilePhone,
    validationFn
  ])

  // Check if we're still loading stuff for the order summary
  const [initialValues, setInitialValues] = useState({
    ...paymentValues,
    customer: {
      ...paymentValues.customer,
      ...(samlDobFormatted ? { dateOfBirth: samlDobFormatted } : {}),
      ...(samlPhoneFormatted ? { phoneNumber: samlPhoneFormatted } : {})
    },
    shipping_address: {
      ...paymentValues.shipping_address,
      ...(samlCookie?.attributes?.AddressLine1?.[0]
        ? { line_1: samlCookie?.attributes?.AddressLine1?.[0] }
        : {}),
      ...(samlCookie?.attributes?.AddressLine2?.[0]
        ? { line_2: samlCookie?.attributes?.AddressLine2?.[0] }
        : {}),
      ...(samlCookie?.attributes?.City?.[0]
        ? { city: samlCookie?.attributes?.City?.[0] }
        : {}),
      ...(samlCookie?.attributes?.State?.[0]
        ? { state: samlCookie?.attributes?.State?.[0] }
        : {}),
      ...(samlCookie?.attributes?.ZipCode?.[0]
        ? { postcode: samlCookie?.attributes?.ZipCode?.[0] }
        : {})
    }
  })
  const [waitingListEnabled, setWaitingListEnabled] = useState(false)

  useEffect(() => {
    const state = user?.state
    if (
      state?.type === 'quiz' &&
      (state.quiz?.triaged || !state.quiz?.isComplete)
    ) {
      const link = getUserStateLink(user.state, '')
      if (link) {
        console.log(
          `Redirecting to ${link}. User state is ${state.type}, quiz ${
            state.quiz?.triaged ? 'triaged' : 'not triaged'
          }, quiz ${state.quiz?.isComplete ? 'complete' : 'not complete'}`
        )
        navigate(link)
      }
    }
  }, [user.state])

  useEffect(() => {
    return () => {
      refreshUserState()
    }
  }, [refreshUserState])

  const availableCountries = useMemo(() => {
    if (currentCountry) {
      return [currentCountry]
    }

    return countries?.length
      ? countries
      : [
          {
            countryCode: 'US',
            countryName: 'United States',
            countryPhoneCode: 1,
            phoneNumberMask: '(999) 999-9999',
            isNorthAmericanCountryCode: true
          }
        ]
  }, [countries, currentCountry])

  // Keep the payment context up to date with the user context...
  useEffect(() => {
    const email = user.email?.replace(/"/g, '')
    if (!user.firstName && !user.lastName && !email) return
    if (
      user.firstName === initialValues.shipping_address.first_name &&
      user.lastName === initialValues.shipping_address.last_name &&
      email === initialValues.customer.email &&
      initialValues.shipping_address.country ===
        availableCountries[0].countryCode
    ) {
      return
    }

    setInitialValues({
      ...initialValues,
      customer: {
        ...initialValues.customer,
        email
      },
      shipping_address: {
        ...initialValues.shipping_address,
        first_name: user.firstName,
        last_name: user.lastName,
        country: availableCountries[0].countryCode
      }
    })
  }, [
    initialValues,
    user.firstName,
    user.lastName,
    user.email,
    availableCountries
  ])

  // Track a checkout view
  useEffect(() => {
    trackUserAction('Checkout View', { includeUnique: true })
  }, [])

  useEffect(() => {
    if (!user.employerSlug) return
    const run = async () => {
      const employer = await api.employer.get({
        employerSlug: user.employerSlug
      })

      if (employer) {
        setWaitingListEnabled(employer.enableWaitingList)
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    run()
  }, [user.employerSlug])

  const onSubmitHandler = useCallback(
    async ({ customerInfo }) => {
      setFormErrors([])

      const { phoneNumber, dateOfBirth } = customerInfo?.customer || {}

      // Make sure countries with a +1 north american phone code have the +1 prepended.
      const currentCountryCode = customerInfo?.shipping_address?.country
      const currentCountry =
        currentCountryCode && getCountryByCode(currentCountryCode)

      const apiPhoneNumber = getApiPhoneNumber(currentCountry, phoneNumber)

      if (customerInfo?.customer && apiPhoneNumber) {
        customerInfo.customer.phoneNumber = apiPhoneNumber
      }

      setUserProps({ phoneNumber: apiPhoneNumber, dateOfBirth }, true)

      // fix state for canada
      if (customerInfo.shipping_address.country === 'CA') {
        customerInfo.shipping_address.state =
          customerInfo.shipping_address.province
      }

      // Ensure email is set
      customerInfo.customer.email = user.email
      customerInfo.customer.employer = user.employerSlug
      customerInfo.customer.workEmail = user.workEmail

      try {
        setLoading(true)
        const address = customerInfo.shipping_address
        await setUserProps({ address })

        const GTMData = {
          enrolled: true
        }

        // GTM Event
        PushToDatalayer({
          event: 'gtm.checkout-transaction-finalized',
          data: GTMData
        })

        PushToDatalayer({
          event: 'gtm.conversion-tracking',
          data: GTMData
        })

        const createProgram = async () => {
          return new Promise((resolve) => {
            const timeout = setTimeout(() => resolve(), 4000)

            api.program
              .createForEnrolledUser({
                userId: user.userId,
                condition: user?.state?.conditionSlug,
                autoAssignCoach: true
              })
              .then(() => {
                clearTimeout(timeout)
                resolve()
              })
              .catch((e) => {
                logErr({
                  err: e,
                  msg: `Program creation onSubmit error`,
                  extra: {
                    userId: user.userId,
                    condition: user?.state?.conditionSlug,
                    resp: e?.response?.data
                  }
                })
                if (e?.response?.status !== 500) {
                  trackUserAction('Program Creation Failed')
                }
              })
          })
        }

        await createProgram()
        await enroll()

        setLoading(false)
      } catch (error) {
        const resp = error?.response?.data
        logErr({
          err: error,
          msg: `PaymentForm.onSubmit error`,
          extra: { userId: user.userId, email: user.email, resp }
        })

        PushToDatalayer({
          event: 'gtm.payment-failed',
          data: JSON.stringify(resp)
        })

        const errors = [translations[translationsKeys.enrollError]]
        setLoading(false)
        setFormErrors(errors)
        return errors
      }
    },
    [
      enroll,
      getCountryByCode,
      setLoading,
      setUserProps,
      translations,
      user.email,
      user.employerSlug,
      user?.state?.conditionSlug,
      user.userId,
      user.workEmail
    ]
  )

  async function onSubmit(customerInfo) {
    console.log('PaymentForm.onSubmit started')

    if (waitingListEnabled) {
      console.log('PaymentForm.onSubmit waitingListEnabled')

      trackUserAction('Waitlist')

      return navigate('/waitlist-confirm', {
        state: { version: 'waitList' }
      })
    }

    return onSubmitHandler({ customerInfo })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validationFn}
      initialValues={initialValues}
      decorators={[focusOnErrors]}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
    >
      {(props) => {
        const { handleSubmit, submitting, values, form, errors } = props
        const disabled =
          Object.keys(errors).length > 0 ||
          waitingListEnabled ||
          formErrors.length > 0 ||
          submitting

        return (
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="flex flex-row flex-wrap justify-between px-5 md:px-8 xl:px-0">
              <div className="w-full mx-auto lg:w-3/5">
                <AddressFields
                  type="shipping_address"
                  changeFields={form.mutators.setValue}
                  availableCountries={availableCountries}
                />
                <CustomerFields
                  values={values}
                  getCountryByCode={getCountryByCode}
                />
              </div>

              <div className="w-full mx-auto lg:w-3/5">
                <div className="pt-4">
                  {formErrors && formErrors.length > 0 && (
                    <div className="mt-4 mb-6">
                      <ErrorMessage messages={formErrors} />
                    </div>
                  )}
                  <Button
                    id="payment-button"
                    isBlock
                    disabled={disabled}
                    type="submit"
                  >
                    {translations[translationsKeys.enrollButton] || 'Enroll'}
                  </Button>
                </div>
                <div className="my-4 text-sm leading-relaxed text-left">
                  <RichText
                    richTextDocument={translations[translationsKeys.terms]}
                    options={{
                      [INLINES.ENTRY_HYPERLINK]: {
                        options: {
                          target: '_blank'
                        }
                      }
                    }}
                  />
                </div>{' '}
              </div>
            </div>
            <FormSpy
              subscription={{ values: true }}
              onChange={(state) => {
                const { values } = state
                setPaymentValues(values)

                // Reset any submission errors when the form state changes.
                setFormErrors([])
              }}
            />
          </form>
        )
      }}
    </Form>
  )
}

const translationsKeys = {
  enrollButton: 'enrollButton',
  terms: 'terms',
  enrollError: 'enrollError'
}

const query = graphql`
  query CheckoutFormQuery {
    allContentfulContentGroup(filter: { readableId: { eq: "checkout-form" } }) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`
