import { useMemo } from 'react'
import { Country } from '../common/CountryProvider'

export const getPhonePlaceHolderAndMask = (country: Country) => {
  const code = country?.countryPhoneCode
  const mask = country?.phoneNumberMask
  const mask_xed = mask?.replaceAll('9', 'X')
  let phonePlaceholder
  let phoneMask
  if (country?.isNorthAmericanCountryCode) {
    phonePlaceholder = mask_xed
    phoneMask = mask
  } else {
    phonePlaceholder = `+${code} ${mask_xed}`
    phoneMask = `+${code} ${mask}`
  }
  return { phonePlaceholder, phoneMask }
}

export const useGetPhonePlaceHolderAndMask = (country: Country) => {
  return useMemo(() => getPhonePlaceHolderAndMask(country), [country])
}

export const getDisplayPhoneNumber = (
  country: Country,
  phoneNumber: string
) => {
  // Make sure countries with a +1 north american phone code have the +1 stripped for display.
  const finalPhoneNumber =
    country?.isNorthAmericanCountryCode && phoneNumber.startsWith('+1')
      ? phoneNumber?.slice(2)
      : phoneNumber
  return finalPhoneNumber
}

export const getApiPhoneNumber = (country: Country, phoneNumber: string) => {
  // Make sure countries with a +1 north american phone code have the +1 prepended.
  const finalPhoneNumber = country?.isNorthAmericanCountryCode
    ? `+${country?.countryPhoneCode} ${
        phoneNumber.startsWith('+1') ? phoneNumber.slice(2) : phoneNumber
      }`
    : phoneNumber

  // Remove any non-digits from the phone number
  const apiPhoneNumber = finalPhoneNumber
    ?.replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll(' ', '')

  return apiPhoneNumber
}
