import React from 'react'

export default function FieldError({ error, submitError }: Props) {
  return (
    <div className="mt-2 text-sm text-ui-error">
      {error || submitError}
    </div>
  )
}

type Props = {
  error?: string
  submitError?: string
}
