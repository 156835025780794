import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Spinner from '../components/common/Spinner'
import SimpleHeader from '../components/Layout/SimpleHeader'
import { PaymentForm } from '../components/Checkout/PaymentForm'
import { NoIndex } from '../components/NoIndex'
import UserIdentificationLayer from '../components/LayerComponents/UserIdentificationLayer'
import { PaymentProvider } from '../contexts/Payment/paymentContext'
import { useLocalizedContent } from '../utils/localization/useLocalizedContent'
import {
  EmployerTier,
  useEmployerContext
} from '../contexts/Employer/employerProvider'

const translationsKeys = {
  title: 'title',
  title_direct: 'title_direct',
  subtitle: 'subtitle'
}

function PaymentPage({ location }) {
  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  const { employer } = useEmployerContext()

  const [isLoading, setLoading] = useState(false)

  return (
    <UserIdentificationLayer location={location}>
      <PaymentProvider>
        <NoIndex />
        {isLoading && (
          <Spinner
            parentStyle={{
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              zIndex: 5000000,
              height: '100%',
              width: '100%',
              background: '#555656a8'
            }}
            spinnerStyle={{
              margin: '0 auto',
              width: '8rem',
              height: '8rem'
            }}
          />
        )}
        <div className="flex flex-col h-full bg-grey-plan">
          <SimpleHeader backButton={false} />
          <div className="flex flex-col w-full bg-grey-plan md:px-0">
            <div className="mx-auto mt-0 flex-center">
              <div className="mx-auto mt-0 flex-center">
                <div className="flex flex-col justify-center px-2 mb-20">
                  <div className="relative w-full px-4 mt-0 mb-4 text-center md:px-0 md:mt-4">
                    <header className="inline-block pb-4 text-center">
                      <h1 className="w-full pt-2 pb-2 text-2xl font-bold text-center text-gp-violet-70">
                        {
                          translations[
                            employer.tier !== EmployerTier.GPDirect
                              ? translationsKeys.title
                              : translationsKeys.title_direct
                          ]
                        }
                      </h1>
                      {employer.tier !== EmployerTier.GPDirect && (
                        <h2 className="text-base leading-relaxed text-center text-black">
                          {translations[translationsKeys.subtitle]}
                        </h2>
                      )}
                    </header>
                  </div>
                  <PaymentForm setLoading={setLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaymentProvider>
    </UserIdentificationLayer>
  )
}

const query = graphql`
  query CheckoutPageQuery {
    allContentfulContentGroup(filter: { readableId: { eq: "checkout-page" } }) {
      nodes {
        ...ContentGroupFragment
      }
    }
  }
`

export default PaymentPage
