import React from 'react'
import AllAddressFields from './AllAddressFields'

export default function AddressFields({
  type,
  changeFields,
  availableCountries
}) {
  return (
    <div>
      <AllAddressFields
        type={type}
        changeFields={changeFields}
        availableCountries={availableCountries}
      />
    </div>

  )
}
