import React from 'react'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'

export default function InputField({
  label,
  name,
  placeholder,
  onChange = () => { },
  showError = undefined,
  ...props
}) {
  return (
    <Field name={name} {...props}>
      {({ input, meta }) => {
        const error =
          showError !== undefined && showError instanceof Function
            ? showError({ input, meta })
            : (meta.error || meta.submitError) && meta.touched

        const labelClass = classNames(
          'inline-block font-bold text-medium-grey text-base mb-2',
          {
            required: props.required,
            'text-ui-error': error
          }
        )

        const inputClass = classNames(
          'block appearance-none w-full bg-white border border-grey-md hover:border-grey-base px-4 py-3 rounded text-black focus:outline-none focus:border-brand-purple',
          {
            'border-ui-error hover:border-ui-error': error
          }
        )

        const attributes = {
          ...Object.entries(props)
            .filter(([key]) => key.match(/data-.+/))
            .reduce((acc, entry) => ({ [entry[0]]: entry[1], ...acc }), {})
        }

        return (
          <div className="relative">
            <FieldLabel name={name} labelClass={labelClass} label={label} />
            <div className="relative">
              <input
                id={name}
                className={inputClass}
                placeholder={placeholder || label}
                autoComplete={'chrome-off'}
                onKeyUp={(e) => {
                  input.onChange(e)
                  if (onChange) {
                    const newVal = e?.target?.value
                    onChange(newVal)
                  }
                }}
                {...input}
                {...attributes}
                onChange={(e) => {
                  input.onChange(e)
                  if (onChange) {
                    const newVal = e?.target?.value
                    onChange(newVal)
                  }
                }}
                disabled={props.disabled}
              />
            </div>

            {error && (
              <FieldError
                error={meta.error}
                submitError={meta.submitError}
              />
            )}
          </div>
        )
      }}
    </Field>
  )
}
