import React, { useMemo } from 'react'
import DatePickerField from './DatePickerField'

export default function BirthDayPicker({ fieldName, label, required }: Props) {
  const maxDate = useMemo(() => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 18)
    return date
  }, [])

  const minDate = useMemo(() => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 116)
    return date
  }, [])

  return (
    <DatePickerField
      readonly
      label={label || 'Date of Birth'}
      name={fieldName}
      required={required}
      maxDate={maxDate}
      minDate={minDate}
    />
  )
}

type Props = {
  label?: string
  fieldName: string
  required?: boolean
}
