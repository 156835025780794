import React, { useState } from 'react'
import states from '../../data/states'
import provinces from '../../data/provinces'
import InputField from './Fields/InputField'
import CountrySelect from './Fields/CountrySelect'
import StateOrProvinceSelect from './Fields/StateOrProvinceSelect'
import StreetField from './StreetField'
import { geocodeByAddress } from '../AutoCompleteAddress'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

function formatAddress({ address_components }) {
  const country = address_components.find((c) =>
    c.types.find((type) => type === 'country')
  )?.short_name
  let number = ''
  let street = ''
  let city = ''
  if (country === 'JP') {
    number = address_components.find((c) =>
      c.types.find((type) => type === 'premise')
    )?.long_name
    street = address_components.find((c) =>
      c.types.find((type) => type === 'sublocality_level_2')
    )?.long_name
    city = address_components.find((c) =>
      c.types.find((type) => type === 'locality')
    )?.long_name
  } else if (country === 'KR') {
    number = address_components.find((c) =>
      c.types.find((type) => type === 'premise')
    )?.long_name
    street = address_components.find((c) =>
      c.types.find((type) => type === 'sublocality_level_4')
    )?.long_name
    city = address_components.find((c) =>
      c.types.find((type) => type === 'sublocality_level_1')
    )?.long_name
  } else {
    number = address_components.find((c) =>
      c.types.find((type) => type === 'street_number')
    )?.long_name
    street = address_components.find((c) =>
      c.types.find((type) => type === 'route')
    )?.long_name
    city = address_components.find((c) =>
      c.types.find((type) => type === 'locality' || type === 'postal_town')
    )?.long_name
  }
  const postcode = address_components.find((c) =>
    c.types.find((type) => type === 'postal_code')
  )?.long_name
  const administrative_area = address_components.find((c) =>
    c.types.find((type) => type === 'administrative_area_level_1')
  )?.short_name
  const line1 = number ? `${number} ${street}` : street
  return { number, street, line1, city, postcode, administrative_area, country }
}

const translationsKeys = {
  countryLabel: 'countryLabel',
  addressLine2Label: 'addressLine2Label',
  cityLabel: 'cityLabel',
  stateLabel: 'stateLabel',
  provinceLabel: 'provinceLabel',
  zipcodeLabel: 'zipcodeLabel',
}

export default function AllAddressFields({
  type,
  changeFields,
  availableCountries
}) {
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState(availableCountries?.[0])

  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  function clearAddressSelect({ country }) {
    changeFields(`customer.phoneNumber`, '')
    changeFields(`${type}.city`, '')
    changeFields(`${type}.line_1`, '')
    changeFields(`${type}.state`, '')
    changeFields(`${type}.province`, '')
    changeFields(`${type}.postcode`, '')
    changeFields(`${type}.country`, country)
  }

  async function handleAddressSelect(address) {
    const results = await geocodeByAddress(address)
    const addressData = results[0]
    const formatted = formatAddress(addressData)
    changeFields(`${type}.city`, formatted.city)
    changeFields(`${type}.line_1`, formatted.line1)
    const newState = states.find(
      (state) => state.id === formatted.administrative_area
    )
    if (newState && formatted.country === 'US') {
      changeFields(`${type}.state`, formatted.administrative_area)
    }
    const newProvince = provinces.find(
      (provinces) => provinces.id === formatted.administrative_area
    )
    if (newProvince && formatted.country === 'CA') {
      changeFields(`${type}.province`, formatted.administrative_area)
    }
    changeFields(`${type}.postcode`, formatted.postcode)
  }

  const addressFieldName = `${type}.line_1`

  return (
    <>
      <div className="addressFields">
        <div className="hidden">
          <div className="md:flex">
            <div className="w-full pr-0 mb-5 md:pr-4">
              <InputField
                name={`${type}.first_name`}
                required
              />
            </div>
            <div className="w-full mb-5">
              <InputField
                name={`${type}.last_name`}
                required
              />
            </div>
          </div>
        </div>

        <div className="md:flex">
          <div className="w-full mb-5">
            <CountrySelect
              name={`${type}.country`}
              label={translations[translationsKeys.countryLabel]}
              options={availableCountries}
              address={address}
              setCountry={setCountry}
              clearAddressSelect={clearAddressSelect}
              required
            />
          </div>
        </div>

        <div className="md:flex">
          <div className="relative w-full mb-5">
            <StreetField
              country={country}
              addressFieldName={addressFieldName}
              translations={translations}
              address={address}
              setAddress={setAddress}
              handleAddressSelect={handleAddressSelect}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full mb-5">
            <InputField
              label={translations[translationsKeys.addressLine2Label]}
              name={`${type}.line_2`}
              placeholder={translations[translationsKeys.addressLine2Label]}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full mb-5">
            <InputField
              label={translations[translationsKeys.cityLabel]}
              name={`${type}.city`}
              placeholder={translations[translationsKeys.cityLabel]}
              required
            />
          </div>
        </div>
        <div className="md:flex">
          <StateOrProvinceSelect
            type={type}
            translations={translations}
            country={country.countryCode}
            required
          />

          <div className="w-full pr-0">
            <InputField
              label={translations[translationsKeys.zipcodeLabel]}
              name={`${type}.postcode`}
              placeholder={translations[translationsKeys.zipcodeLabel]}
              required
            />
          </div>
        </div>
      </div>
    </>
  )
}

const query = graphql`
  query AddressFieldsQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "address-form-fields"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`
