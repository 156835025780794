import React from 'react'
import classNames from 'classnames'
import { Field } from 'react-final-form'

export default function CountrySelect({
  name,
  label,
  options,
  setCountry,
  clearAddressSelect,
  ...props
}) {
  return (
    <Field name={name} options={options} {...props}>
      {({ input, meta }) => {
        const error = meta.error && (meta.touched || props.address)

        const labelClass = classNames(
          'mb-2 inline-block font-bold text-medium-grey text-base',
          {
            'text-ui-error': error
          },
          {
            required: props.required
          }
        )

        const attributes = {
          ...Object.entries(props)
            .filter(([key]) => key.match(/data-.+/))
            .reduce((acc, entry) => ({ [entry[0]]: entry[1], ...acc }), {})
        }

        const changeValue = (event) => {
          setCountry({
            countryCode: event.target.value,
            countryName:
              event.target.selectedOptions[0].attributes['data-name'].value
          })
          clearAddressSelect({ country: event.target.value })
        }

        return (
          <div className="relative">
            {label && (
              <label htmlFor={name} className={labelClass}>
                {label}
              </label>
            )}
            <div className="relative">
              <select
                id={name}
                className="block w-full py-3 pl-4 pr-10 bg-white border rounded appearance-none border-grey-md hover:border-grey-base focus:outline-none focus:border-brand-purple"
                {...props}
                {...attributes}
                onChange={changeValue}
                disabled={options?.length <= 1}
              >
                {options.map((opt) => {
                  return (
                    <option
                      key={opt.countryCode}
                      value={opt.countryCode}
                      data-name={opt.countryName}
                    >
                      {opt.countryName}
                    </option>
                  )
                })}
              </select>
              <div className="absolute bottom-0 right-0 flex items-center h-10 px-4 pb-2 pointer-events-none text-grey-darker">
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9 14"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#131313"
                    strokeWidth="1.5"
                    transform="translate(1 1)"
                  >
                    <polyline points="0 9 3.5 12 7 9" />
                    <polyline points="0 3 3.5 0 7 3" />
                  </g>
                </svg>
              </div>
            </div>
            {error && (
              <div className="mt-2 text-sm text-ui-error">{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}
