import React from 'react'

export default function FieldLabel({ name, labelClass, label }: Props) {
  return (
    <label htmlFor={name} className={labelClass}>
      {label}
    </label>
  )
}

type Props = {
  name: string
  label: string
  labelClass: string
}
