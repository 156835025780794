import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import { Calendar } from 'primereact/calendar'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import { Dropdown } from 'primereact/dropdown'

export default function DatePickerField({
  label,
  name,
  showError = undefined,
  minDate,
  maxDate,
  readonly,
  ...props
}: Props) {
  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="leading-none"
      />
    )
  }, [])

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="pl-2 ml-2 leading-none"
      />
    )
  }, [])

  const yearRange = useMemo(() => {
    return `${minDate.getFullYear()}:${maxDate.getFullYear()}`
  }, [maxDate, minDate])

  return (
    <Field name={name} {...props}>
      {({ input, meta }) => {
        const error =
          showError !== undefined && showError instanceof Function
            ? showError({ input, meta })
            : (meta.error || meta.submitError) && meta.touched

        const labelClass = classNames(
          'inline-block font-bold text-medium-grey text-base mb-2',
          {
            required: props.required,
            'text-ui-error': error
          }
        )

        const inputClass = classNames(
          'block appearance-none w-full bg-white border border-grey-md hover:border-grey-base px-4 py-3 rounded text-black focus:outline-none focus:border-brand-purple',
          {
            'border-ui-error hover:border-ui-error': error
          }
        )

        return (
          <div className="relative mb-4">
            <FieldLabel name={name} labelClass={labelClass} label={label} />
            <div className="relative">
              <Calendar
                readOnlyInput={readonly}
                inputClassName={inputClass}
                monthNavigator
                yearNavigator
                minDate={minDate}
                maxDate={maxDate}
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
                yearRange={yearRange}
                {...props}
                {...input}
              />
            </div>
            {error && (
              <FieldError error={meta.error} submitError={meta.submitError} />
            )}
          </div>
        )
      }}
    </Field>
  )
}
type Props = {
  label: string
  name: string
  showError?: any
  minDate: Date
  maxDate: Date
  value?: Date
  required?: boolean
  readonly?: boolean
}
