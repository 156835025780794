import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
  useMemo
} from 'react'
import * as actions from '../Payment/paymentActions'

const PaymentContext = createContext(null)

const addressValues = {
  first_name: null,
  last_name: null,
  line_1: null,
  line_2: null,
  country: null,
  city: null,
  state: null,
  province: null,
  postcode: null
}

export const defaultState = {
  paymentValues: {
    customer: {
      email: '',
      marketing_opt_in: false
    },
    shipping_address: addressValues,
  }
}

function paymentReducer(state, action) {
  const { payload } = action
  switch (action.type) {
    case actions.SET_PAYMENT_VALUES: {
      return {
        ...state,
        paymentValues: payload
      }
    }
    default:
      return state
  }
}

export function PaymentProvider({ initialState = defaultState, children }) {
  const [state, dispatch] = useReducer(paymentReducer, initialState)

  const setPaymentValues = useCallback(
    (values) => {
      dispatch({ type: actions.SET_PAYMENT_VALUES, payload: values })
    },
    [dispatch]
  )

  const contextValue = useMemo(
    () => ({
      ...state,
      setPaymentValues,
      dispatch
    }),
    [setPaymentValues, state]
  )

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  )
}

export const usePaymentContext = () => useContext(PaymentContext)
