import React, { useState } from 'react'
import classNames from 'classnames'
import states from '../../../data/states'
import provinces from '../../../data/provinces'
import { Field } from 'react-final-form'

const translationKeys = {
  stateLabel: 'stateLabel',
  provinceLabel: 'provinceLabel',
}

export default function StateOrProvinceSelect({
  type,
  translations,
  country,
  dataType,
  ...props
}) {
  const [hasChanged, setHasChanged] = useState(false)

  let options = []
  let name = ''
  let label = ''
  if (country === 'US') {
    const selectStateOption = {
      id: '',
      name: translations[translationKeys.stateLabel]
    }
    options = [selectStateOption, ...states]
    name = `${type}.state`
    label = translations[translationKeys.stateLabel]
    props[dataType] = 'state'
  } else if (country === 'CA') {
    const selectProvinceOption = {
      id: '',
      name: translations[translationKeys.provinceLabel]
    }
    options = [selectProvinceOption, ...provinces]
    name = `${type}.province`
    label = translations[translationKeys.provinceLabel]
    props[dataType] = 'state'
  }

  return country === 'US' || country === 'CA' ? (
    <div className="w-full pr-0 mb-5 md:pr-4 md:mb-0">
      <Field name={name} options={options} {...props}>
        {({ input, meta }) => {
          const error = meta.error && (meta.touched || props.address)

          const labelClass = classNames(
            'mb-2 inline-block font-bold text-medium-grey text-base',
            {
              'text-ui-error': error
            },
            {
              required: props.required
            }
          )

          const attributes = {
            ...Object.entries(props)
              .filter(([key]) => key.match(/data-.+/))
              .reduce((acc, entry) => ({ [entry[0]]: entry[1], ...acc }), {})
          }

          const changeValue = () => {
            setHasChanged(true)
          }

          return (
            <div className="relative">
              {label && (
                <label htmlFor={name} className={labelClass}>
                  {label}
                </label>
              )}
              <div className="relative">
                <select
                  id={name}
                  className="block w-full py-3 pl-4 pr-10 leading-6 bg-white border rounded appearance-none border-grey-md hover:border-grey-base focus:outline-none focus:border-brand-purple"
                  {...props}
                  {...attributes}
                  onChange={e => {
                    input.onChange(e)
                    changeValue(e)
                  }}
                >
                  {options.map((opt) => {
                    return (
                      <option
                        key={opt.id}
                        value={opt.id}
                        data-name={opt.name}
                        selected={!hasChanged && opt.id === input.value}
                      >
                        {opt.name}
                      </option>
                    )
                  })}
                </select>
                <div className="absolute bottom-0 right-0 flex items-center h-10 px-4 pb-2 pointer-events-none text-grey-darker">
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 9 14"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#131313"
                      strokeWidth="1.5"
                      transform="translate(1 1)"
                    >
                      <polyline points="0 9 3.5 12 7 9" />
                      <polyline points="0 3 3.5 0 7 3" />
                    </g>
                  </svg>
                </div>
              </div>
              {error && (
                <div className="mt-2 text-sm text-ui-error">{meta.error}</div>
              )}
            </div>
          )
        }}
      </Field>
    </div>
  ) : null
}
