import React, { useMemo } from 'react'
import Input from '../Input'
import { useGetPhonePlaceHolderAndMask } from './checkoutHelper'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'
import BirthDayPicker from './Fields/BirthDayPicker'

const translationsKeys = {
  birthdayLabel: 'birthdayLabel',
  phoneLabel: 'phoneLabel',
  phoneLabelNote: 'phoneLabelNote'
}

export default function CustomerFields({ values, getCountryByCode }) {
  const currentCountry = useMemo(
    () =>
      values?.shipping_address?.country &&
      getCountryByCode(values?.shipping_address?.country),
    [getCountryByCode, values?.shipping_address?.country]
  )
  // Don't require the user to put in the country code if it's +1. We'll add it implicitly on form submission.
  const { phonePlaceholder, phoneMask } = useGetPhonePlaceHolderAndMask(currentCountry)

  const data = useStaticQuery(query)
  const { translations } = useLocalizedContent(data)

  return (
    <>
      <div className="w-full py-2 mt-2">
        <BirthDayPicker
          label={translations[translationsKeys.birthdayLabel]}
          fieldName="customer.dateOfBirth"
          required
        />
        <div className="md:flex">
          <div className="relative w-full mb-5">
            <input
              type="email"
              name="customer.email"
              className="hidden bg-grey-plan"
              required
              disabled
            />
            <div>
              <Input
                label={translations[translationsKeys.phoneLabel]}
                placeholder={phonePlaceholder}
                mask={phoneMask}
                pattern={/[^\d]/g}
                description={translations[translationsKeys.phoneLabelNote]}
                required
                name="customer.phoneNumber"
              />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

const query = graphql`
  query CustomerFieldsQuery {
    allContentfulContentGroup(filter: {readableId: {eq: "address-form-fields"}}) {
      nodes {
        ...ContentGroupFragment
      }   
    }
  }
`
